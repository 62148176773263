import React, { useState, useContext, useEffect } from "react";
import {
  ScrollView,
  View,
  TouchableOpacity,
  Text,
  Image,
  Button,
} from "react-native";
import { isBrowser, isMobile } from "react-device-detect";

import ResumoSetor from "../../components/ResumoSetor";
import Modalidade from "../../components/Modalidade";
import url from "url";

import { formatReal } from "../../helpers/Format";

// Styles
import styles from "./styles";

import EventoContext from "../../contexts/evento";

// TESTE INGRESSO
import CorpoIngresso from "../../../assets/images/corpo-ingresso.svg";
import IframeReconhecimento from "../../components/IframeReconhecimento";

// import * as WebBrowser from 'expo-web-browser';
import AuthContext from "../../contexts/auth";
import LightboxContext from "../../contexts/lightbox";

const EscolhaIngressos = () => {
  //get Url params

  const { evento } = useContext(EventoContext);

  const { setorSelecionado, setSetorSelecionado } = useContext(EventoContext);

  const [iframeOpen, setIframeOpen] = useState(false);

  useEffect(() => {
    getURLParams();
  }, []);

  const getURLParams = () => {
    let urlObject = url.parse(window.location.href, true);

    if (urlObject.query && urlObject.query.selected_setor) {
      let setor = evento.etapa.setores.find(
        (setor) => setor._id == urlObject.query.selected_setor
      );
      setSetorSelecionado(setor);
    }
  };

  return (
    <View style={styles.container}>
      {evento.etapa.setores.length > 0 && (
        <ScrollView style={styles.divisoria1}>
          {evento.etapa.setores.map((setor, i) => (
            <View key={i}>
              <TouchableOpacity
                key={setor._id}
                onPress={() => {
                  setSetorSelecionado(setor);
                }}
              >
                <ResumoSetor
                  setor={setor}
                  nome={setor.nome}
                  selecionado={setorSelecionado?._id == setor._id}
                />
              </TouchableOpacity>

              {isMobile && setorSelecionado?._id == setor._id && (
                <View>
                  {setorSelecionado?.lotes.map((ingresso) => (
                    <Modalidade
                      key={`modalidade-${ingresso._id}`}
                      ingresso={ingresso}
                    />
                  ))}
                </View>
              )}
            </View>
          ))}
        </ScrollView>
      )}

      {!isMobile && (
        <ScrollView style={styles.divisoria2}>
          {setorSelecionado?.lotes.length == 0 && (
            <View style={{ paddingVertical: 20 }}>
              <Text
                style={{ textAlign: "center", fontSize: 16, color: "#a0a0a0" }}
              >
                Nenhum ingresso disponível para este setor
              </Text>
            </View>
          )}
          {setorSelecionado?.lotes.map((ingresso) => (
            <Modalidade key={ingresso._id} ingresso={ingresso} />
          ))}
        </ScrollView>
      )}
    </View>
  );
};

export default EscolhaIngressos;
