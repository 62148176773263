import React, { useState } from 'react'
import { View, Text, Image, TouchableOpacity } from 'react-native'
import styles from './styles'

import DefinirTitular from '../DefinirTitular';

import { formatCPF, formatCelular } from '../../helpers/Format'

import CorpoIngresso from '../../../assets/images/corpo-ingresso-2.svg'
import Colors from '../../values/colors';

const IngressoAcompanhante = ({ ingresso, styleInput }) => {
    const [editOpen, setEditOpen] = useState(false)

    const editarTitular = (bool) => {
        setEditOpen(bool)
    }

    return (
        <View>
            <View style={[{ width: 343, height: 132.42 }, styleInput]}>
                <Image
                    style={{
                        width: 177.4,
                        height: 130.42,
                        position: 'absolute',
                        borderTopRightRadius: 31,
                        borderBottomRightRadius: editOpen ? 0 : 31,
                        top: 0,
                        right: 0
                    }}
                    source={{
                        uri: ingresso.evento?.path_imagem
                    }} />
                <Image style={{
                    width: 343,
                    height: 130.42
                }} source={{
                    uri: CorpoIngresso
                }} />

                <View style={{ position: 'absolute', top: 0, left: 50 /*35*/ /*65*/, width: 175, height: 132.42, paddingVertical: 5, justifyContent: 'space-between' }}>
                    <Text style={{ color: '#FFF', fontSize: 14, fontWeight: 700, letterSpacing: -1 }}>ACOMPANHANTE!</Text>
                    <View>
                        <Text style={{ color: '#FFF', fontSize: 14, fontWeight: 700, letterSpacing: -1 }}>{ingresso.evento?.nome.toUpperCase()}</Text>
                        <Text style={{ color: '#FFF', fontSize: 14, fontWeight: 200, letterSpacing: -1 }}>{ingresso.lote?.setor?.nome.toUpperCase()} - {ingresso.lote.modalidade?.nome.toUpperCase()} - {ingresso.lote?.nome.toUpperCase()}</Text>
                    </View>
                    {ingresso.titular && <View>
                        <Text style={{ color: '#FFF', fontSize: 12, fontWeight: 500, letterSpacing: -1 }}>{ingresso.titular?.nome}</Text>
                        <Text style={{ color: '#FFF', fontSize: 12, fontWeight: 500, letterSpacing: -1 }}>CPF: {ingresso.titular?.cpf ? formatCPF(ingresso.titular.cpf) : "Não definido"}</Text>
                    </View>}
                    {ingresso.titular && <View>
                        <Text style={{ color: '#FFF', fontSize: 12, fontWeight: 600, letterSpacing: -1 }}>{ingresso.titular?.celular ? formatCelular(ingresso.titular?.celular) : ""}</Text>
                    </View>}

                    {(!ingresso.titular && !editOpen) && <TouchableOpacity style={{ position: 'absolute', bottom: 5 }} onPress={() => { editarTitular(true) }}>
                        <Text style={{ color: Colors.attention, fontSize: 12, fontWeight: 600, letterSpacing: -1 }}>Definir titular</Text>
                    </TouchableOpacity>}
                </View>
            </View>
            {editOpen && <DefinirTitular ingresso={ingresso} fecharDefinicaoTitular={editarTitular} />}
        </View>
    )
}

export default IngressoAcompanhante