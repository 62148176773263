import React, { useContext, useEffect } from "react";
import { ScrollView, View, Text, Image, Button } from "react-native";
import { isMobile } from "react-device-detect";

import SucessoCompra from "../../../assets/images/sucesso_compra.svg";

import styles from "./styles";
import Colors from "../../values/colors";

import Ingresso from "../../components/Ingresso";
import IngressoAcompanhante from "../../components/IngressoAcompanhante";

import API from "../../services/API";

// Contexts
import CompraContext from "../../contexts/compra";
import LightboxContext from "../../contexts/lightbox";
import AuthContext from "../../contexts/auth";

const Confirmacao = () => {
  const { fecharLightbox } = useContext(LightboxContext);
  const { reconhecimentoFeito } = useContext(AuthContext);
  const {
    compra,
    ingressos,
    putIngresso,
    tokenReserva,
    modoPagamento,
    setIngressos,
    setIngressosAcompanhantes,
    ingressosAcompanhantes
  } = useContext(CompraContext);

  useEffect(() => {
    iniciarBuscaIngressos(compra._id);
  }, []);

  const iniciarBuscaIngressos = async (compra_id) => {

    let ingressos = await buscarIngressos(compra_id);
    if (!ingressos) {
      setTimeout(() => {
        buscarIngressos(compra_id);
      }, 5000);
    }
  };

  const buscarIngressos = async (compra_id) => {
    try {
      let ingressoResponse = await API.get(`/ingressos/compra/${compra_id}`);
      let ings = ingressoResponse.data;

      let ingressos = ings.ingressos || [];
      let ingressosAcompanhantes = ings.ingressosAcompanhantes || [];

      setIngressos(ingressos);
      setIngressosAcompanhantes(ingressosAcompanhantes);

      return true;
    } catch (error) {
      //console.log("ERROR", error)
      setTimeout(() => {
        buscarIngressos(compra_id);
      }, 3000);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <ScrollView style={{ flex: 1, height: "100%" }}>
        <View style={styles.container}>
          <View style={styles.divisoria1}>
            <Text
              style={{
                fontSize: 22,
                fontWeight: 600,
                textAlign: "center",
                marginTop: isMobile ? 20 : 0,
              }}
            >
              {reconhecimentoFeito ? "Cadastro de reconhecimento facial realizado com sucesso e compra concluída" : "Você adquiriu os ingressos com sucesso!"}
            </Text>

            <Image
              style={{
                width: 1074 / (isMobile ? 4 : 2.25),
                height: 739 / (isMobile ? 4 : 2.25),
                marginVertical: 30,
              }}
              source={{
                uri: SucessoCompra,
              }}
            />

            <Text
              style={{
                fontSize: 16,
                fontWeight: 400,
                maxWidth: "80%",
                textAlign: "center",
              }}
            >
              Seu comprovante foi enviado para o seu e-mail e também pode ser
              encontrado em Minha Área no site do iPass
            </Text>
          </View>
          <View style={styles.divisoria2}>
            {/* <Text style={{ fontSize: 16, fontWeight: 400, maxWidth: '80%', textAlign: 'center' }}>Para entrar no evento, libere o QR Code definindo um titular para cada um dos ingressos adquiridos</Text> */}

            <View style={{ marginVertical: 30 }}>
              {/*ingressos.map((ingresso, index) => <Ingresso key={ingresso.uuid} ingresso={ingresso} styleInput={index > 0 ? { marginTop: 20 } : {}} />)}

                    {ingressos.length != compra.ordens_emissao_ingresso.length && <Text style={{ marginTop: 20, textAlign: 'center' }}>Carregando ingressos...</Text>*/}

              {ingressos?.length ? (
                ingressos.map((ingresso, index) => (
                  <Ingresso
                    key={ingresso._id}
                    ingresso={ingresso}
                    styleInput={index > 0 ? { marginTop: 20 } : {}}
                  />
                ))
              ) : (
                <Text style={{ marginTop: 20, textAlign: "center" }}>
                  Carregando ingressos...
                </Text>
              )}
            </View>
            {ingressos.filter((i) => !i.titular).length > 0 && (
              <Button
                color={Colors.primary}
                title="Definir titulares depois"
                onPress={() => {
                  fecharLightbox();
                }}
              />
            )}

            <View style={{ marginVertical: 30 }}>
              {/*ingressos.map((ingresso, index) => <Ingresso key={ingresso.uuid} ingresso={ingresso} styleInput={index > 0 ? { marginTop: 20 } : {}} />)}

                    {ingressos.length != compra.ordens_emissao_ingresso.length && <Text style={{ marginTop: 20, textAlign: 'center' }}>Carregando ingressos...</Text>*/}

              {ingressosAcompanhantes?.length ? (
                ingressosAcompanhantes.map((ingresso, index) => (
                  <IngressoAcompanhante
                    key={ingresso._id}
                    ingresso={ingresso}
                    styleInput={index > 0 ? { marginTop: 20 } : {}}
                  />
                ))
              ) : (
                <Text style={{ marginTop: 20, textAlign: "center" }}>
                  {ingressosAcompanhantes?.length ? 'Carregando ingresso do acompanhante...' : ''}
                </Text>
              )}
            </View>

            {/* <Text style={{ fontSize: 16, fontWeight: 400, maxWidth: '80%', textAlign: 'center' }}>Você também pode definir depois no site do iPass.</Text>
                        <Text style={{ fontSize: 16, fontWeight: 400, maxWidth: '80%', textAlign: 'center', marginBottom: 30 }}>Mas não se esqueça de definir antes do evento.</Text> */}

          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default Confirmacao;
