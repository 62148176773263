import React, { useState, createContext, useEffect } from "react";

const EventoContext = createContext({});

export const EventoProvider = ({ children }) => {
  const [evento, setEvento] = useState(null);
  const [setores, setSetores] = useState([]);
  const [tokenEvento, setTokenEvento] = useState(null);
  const [setorSelecionado, setSetorSelecionado] = useState(null);

  const getSetor = (setor_id) => {
    //console.log("getSetor", setor_id)
    let setores = evento.etapa.setores;
    for (let s of setores) {
      if (s._id == setor_id) return s;
    }
    return null;
  };

  return (
    <EventoContext.Provider
      value={{
        evento,
        setEvento,
        setores,
        setSetores,
        getSetor,
        tokenEvento,
        setTokenEvento,
        setorSelecionado,
        setSetorSelecionado,
      }}
    >
      {children}
    </EventoContext.Provider>
  );
};

export default EventoContext;
