import React, { useRef, useEffect, useState, useContext, use } from "react";
import {
  View,
  Text,
  Button,
  Image,
  ActivityIndicator,
  Dimensions,
  ScrollView,
} from "react-native";
import { Form } from "@unform/mobile";
import Input from "../../components/Form/input";
import { isBrowser, isMobile } from "react-device-detect";
import Select from "react-select";


import * as Linking from "expo-linking";

import LightboxContext from "../../contexts/lightbox";

import MyUniverse from "../../../assets/images/my-universe.svg";

import styles from "./styles";
import Colors from "../../values/colors";

import * as Yup from "yup";
import API from "../../services/API";
import AuthContext from "../../contexts/auth";
import CompraContext from "../../contexts/compra";
import { TouchableOpacity } from "react-native-gesture-handler";
import Avatar from "../../components/Avatar";
import TicketLayout from "../../components/TicketLayout";
import EventoContext from "../../contexts/evento";
import Icon from "react-native-vector-icons/FontAwesome";
import TitularesContext from "../../contexts/titulares";
import InputMask from "../../components/Form/inputMask";
import { calcDateDiff, validCPF } from "@renegoncalves/rg-helper";
import { validatePhone } from "validations-br";
import moment from "moment";
import PickerSelect from "../../components/Form/select";

const getDimensiosImage = ({ width, height }) => {
  let scale = 1;

  if (height < 900) scale = 1.25;
  if (height < 800) scale = 1.5;
  if (height < 700) scale = 1.75;
  if (height < 600) scale = 2;
  if (height < 500) scale = 3.5;
  if (height < 400) scale = 4;

  let finalWidth = 586.2920625 / scale;
  let finalHeight = 480.337665 / scale;

  return {
    width: finalWidth,
    height: finalHeight,
  };
};


const selectStyles = {
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? "#01AC8A" : base.borderColor,
    boxShadow: state.isFocused ? "0 0 0 1px #01AC8A" : base.boxShadow,
    "&:hover": {
      borderColor: "#01AC8A",
    },
  }),
  option: (base, state) => {
    let backgroundColor = base.backgroundColor;
    let color = base.color;
    if (state.isSelected) {
      backgroundColor = "#01AC8A";
      color = "#FFF";
    } else if (state.isFocused) {
      backgroundColor = "#CCF2EA";
      color = "#000";
    }
    return {
      ...base,
      backgroundColor,
      color,
    };
  },
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),

  menu: (base) => ({
    ...base,
    marginTop: 0,
  }),
};



const SelecionarTitular = () => {
  const { pagina, setPagina } = useContext(LightboxContext);
  const { pessoa, setPessoa, setTokenAuth, logout } = useContext(AuthContext);
  const { reservarIngressos, carrinho } = useContext(CompraContext);
  const { evento, setorSelecionado } = useContext(EventoContext);
  const { adicionarTitular, titulares, setTitulares } =
    useContext(TitularesContext);

  const formRef = useRef(null);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [errorLogin, setErrorLogin] = useState(null);
  const [loading, setLoading] = useState(false);

  // const [titulares, setTitulares] = useState([]);

  const [numEtapas, setNumEtapas] = useState(1);
  const [etapaAtual, setEtapaAtual] = useState(0);
  const [titularAtual, setTitularAtual] = useState(null);

  const [errorEtapa, setErrorEtapa] = useState(null);

  const [ingressosNaoTitulados, setIngressosNaoTitulados] = useState([]);
  const [titulo, setTitulo] = useState("Selecionar Titular");

  const [selectedExtras, setSelectedExtras] = useState({});


  useEffect(() => {
    if (carrinho) {
      setIngressosNaoTituladosByCarrinho();
    }
  }, [carrinho]);

  useEffect(() => {
    if (titulares[etapaAtual]?.acompanhante) {
      setTitulo("Selecionar Acompanhante");
    } else {
      setTitulo("Selecionar Titular");
    }
  }, [etapaAtual, titulares]);

  const setIngressosNaoTituladosByCarrinho = () => {
    let ingressos = [];

    carrinho.forEach((item) => {
      for (let i = 0; i < item.quantidade; i++) {
        ingressos.push({
          ingresso: item,
          titular: null,
          checked: false,
          error: false,
          acompanhante: false,
        });

        if (item.lote.setor.gerar_ingresso_grupo) {
          const quantidadeExtras = item.lote.setor.quantidade_ingressos_grupo || 1;

          for (let j = 0; j < quantidadeExtras; j++) {
            ingressos.push({
              ingresso: item,
              titular: null,
              checked: false,
              error: false,
              acompanhante: true,
            });
          }
        }
      }
    });

    setTitulares(ingressos);
  };


  const handleCheckDuplicatedCPF = async (cpf, evento_etapa_id, index) => {
    try {
      console.log("handleCheckDuplicatedCPF titulares", titulares);
      let duplicado = false;
      titulares.forEach((titular, i) => {
        if (titular.titular && titular.titular.cpf === cpf && index != i) {
          duplicado = true;
        }
      });

      if (duplicado) throw "Você já selecionou esse titular em outro ingresso";

      try {
        await API.post(`/ingressos/titulares/verificar/cpf`, {
          cpf,
          evento_etapa_id,
        });
      } catch (error) {
        console.error(error);
        console.error(error?.data);
        throw typeof error?.data == "string"
          ? error.data
          : "Ocorreu um erro inesperado. Aguarde alguns segundos e tente novamente!";
      }

      return false;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleNextEtapa = async (data) => {
    try {
      setLoading(() => true);
      setErrorEtapa(null);
      formRef.current.setErrors({});

      data.data_nascimento = data.data_nascimento.split("/").reverse().join("-");
      data.cpf = data.cpf.split(".").join("").split("-").join("");
      data.celular = data.celular
        .split("(").join("")
        .split(")").join("")
        .split("-").join("")
        .split(" ").join("");

      let shape = {
        nome: Yup.string()
          .test("nome-sobrenome", "Nome e sobrenome obrigatórios", (value) =>
            value.split(" ").length > 1
          )
          .required("Campo obrigatório!"),
        cpf: Yup.string()
          .test("is-cpf", "CPF inválido", (value) => validCPF(value))
          .required("Campo obrigatório!"),
        celular: Yup.string()
          .test("is-phone", "Celular inválido", (value) => validatePhone(value))
          .required("Campo obrigatório!"),
        email: Yup.string()
          .email("E-mail inválido")
          .required("Campo obrigatório!"),
        data_nascimento: Yup.string()
          .test("is-date-valid", "Data inválida", (value) => {
            const diff = calcDateDiff(value, moment(), "years");
            return !isNaN(Date.parse(value)) && diff < 0 && diff > -120;
          })
          .required("Campo obrigatório!"),
      };

      let { extra_fields = [] } = titulares[etapaAtual]?.ingresso?.lote?.setor;
      if (extra_fields.length > 0) {
        let extrasShape = {};
        extra_fields.forEach((campo) => {
          extrasShape[campo.field_name] = Yup.string().required(
            `Selecione uma opção para ${campo.field_name}`
          );
        });
        shape.extras = Yup.object().shape(extrasShape);
      }

      data.extras = { ...selectedExtras };

      const schema = Yup.object().shape(shape);
      await schema.validate(data, { abortEarly: false });

      data.extras = Object.entries(data.extras).map(([fieldName, valor]) => ({
        nome: fieldName,
        valor,
      }));

      try {
        if (!evento.permitir_cpf_duplicado) {
          await handleCheckDuplicatedCPF(
            data.cpf,
            titulares[etapaAtual]?.ingresso?.lote?.setor?.evento_etapa,
            etapaAtual
          );
        }
      } catch (error) {
        setErrorEtapa(
          typeof error === "string"
            ? error
            : "Você já selecionou esse titular em outro ingresso"
        );
        let newTitulares = [...titulares];
        newTitulares[etapaAtual] = {
          ...titulares[etapaAtual],
          checked: true,
          error: true,
        };
        setTitulares(newTitulares);
        return;
      }

      let newTitulares = [...titulares];
      newTitulares[etapaAtual] = {
        ...titulares[etapaAtual],
        titular: data,
        checked: true,
        error: false,
      };
      setTitulares(newTitulares);

      if (etapaAtual + 1 < newTitulares.length && !setorSelecionado.gerar_ingresso_grupo) {
        handleChangeEtapa(etapaAtual + 1);
        return;
      }

      if (setorSelecionado.gerar_ingresso_grupo) {
        if (etapaAtual + 1 < newTitulares.length) {
          handleChangeEtapa(etapaAtual + 1);
          return;
        }
      }

      if (newTitulares.find((titular) => !titular.checked)) {
        setErrorEtapa("Você precisa selecionar todos os titulares");
        return;
      }

      let titularesReserva = newTitulares.map((t) => ({
        ...t.titular,
        lote_id: t?.ingresso?.lote?._id,
        acompanhante: t?.acompanhante,
      }));

      reservarIngressos({ titulares: titularesReserva });
    } catch (error) {
      console.log("ERRO", error);
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((err) => {
          errorMessages[err.path] = err.message;
        });
        formRef.current.setErrors(errorMessages);
      }
    } finally {
      setLoading(false);
    }
  };


  const handleChangeEtapa = (index) => {
    if (titulares[index]?.titular) {
      let titular = titulares[index].titular;

      formRef.current.setData({
        ...titular,
        data_nascimento: moment(titular.data_nascimento).format("DD/MM/YYYY"),
        extras: titular.extras
          ? titular.extras.reduce(
            (accumulator, extra) => ({
              ...accumulator,
              [extra.nome]: extra.valor,
            }),
            {}
          )
          : undefined,
      });

      if (titular.extras && titular.extras.length > 0) {
        let extrasMap = {};
        titular.extras.forEach((ex) => {
          extrasMap[ex.nome] = ex.valor;
        });
        setSelectedExtras(extrasMap);
      } else {
        setSelectedExtras({});
      }

    } else {
      formRef.current.reset();
      setSelectedExtras({});
    }

    setEtapaAtual(index);
    formRef.current.setErrors({});
  };



  return (
    <View style={styles.container}>
      <Text
        style={{
          fontSize: 24,
          marginBottom: isMobile ? 2 : 10,
          textAlign: "center",
        }}
      >
        {titulo}
      </Text>

      {errorEtapa && (
        <Text
          style={{
            fontSize: 16,
            marginBottom: isMobile ? 2 : 10,
            textAlign: "center",
            color: "red",
          }}
        >
          {errorEtapa}
        </Text>
      )}

      {titulares.length > 1 && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {titulares.map((ingresso, i) => {
            return (
              <View
                key={i}
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <TouchableOpacity
                  style={{
                    width: 30,
                    height: 30,
                    backgroundColor: etapaAtual == i ? "#53D769" : "#017781",
                    borderRadius: 15,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onPress={() => handleChangeEtapa(i)}
                >
                  {ingresso.checked === true && (
                    <View
                      style={{
                        position: "absolute",
                        top: -5,
                        right: -5,
                        padding: 2,
                        backgroundColor: "#ffffff",
                        borderRadius: 15,
                      }}
                    >
                      <Icon
                        name={ingresso.error ? "times" : "check"}
                        color={ingresso.error ? "red" : "#53D769"}
                        size={12}
                      />
                    </View>
                  )}
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: "500",
                      color: "#ffffff",
                    }}
                  >
                    {i + 1}
                  </Text>
                </TouchableOpacity>

                {i < titulares.length - 1 && (
                  <View
                    style={{
                      width: 20,
                      height: 1,
                      backgroundColor: "#017781",
                    }}
                  ></View>
                )}
              </View>
            );
          })}
        </View>
        // </ScrollView>
      )}

      <SelecionarTitularIngresso
        evento={evento}
        ingresso={titulares[etapaAtual]?.ingresso}
        formRef={formRef}
        handleSubmit={handleNextEtapa}
        error={errorEtapa}
        etapaAtual={etapaAtual}
        numEtapas={titulares.length}
        loading={loading}
        setSelectedExtras={setSelectedExtras}
        selectedExtras={selectedExtras}
      />
    </View>
  );
};

export default SelecionarTitular;

const SelecionarTitularIngresso = ({
  ingresso,
  evento,
  formRef,
  handleSubmit,
  error,
  etapaAtual,
  numEtapas,
  loading,
  setSelectedExtras,
  selectedExtras
}) => {
  return (
    <ScrollView
      style={{
        height: isMobile ? 300 : "100%",
        textAlign: "center",
        paddingTop: 10,
        paddingHorizontal: 20,
      }}
    >
      <Text
        style={{
          fontSize: 18,
          marginBottom: isMobile ? 2 : 10,
          marginTop: isMobile ? 2 : 10,
          textAlign: "center",
        }}
      >
        Etapa - {etapaAtual + 1} de {numEtapas}
      </Text>

      <View
        style={{
          alignItems: "center",
          marginBottom: isMobile ? 2 : 20,
        }}
      >
        <TicketLayout ingresso={ingresso} evento={evento} />
      </View>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input
          name="nome"
          placeholder="Nome"
          label="Nome"
          //size={!isMobile ? 22 : 14}
          color={"#313131"}
        />
        <View
          style={{
            flexDirection: isMobile ? "column" : "row",
            gap: isMobile ? 0 : 20,
          }}
        >
          <InputMask
            type="cpf"
            name="cpf"
            keyboardType="numeric"
            label="CPF"
            placeholder="CPF"
          />

          <InputMask
            type="cel-phone"
            name="celular"
            keyboardType="numeric"
            label="Celular"
            placeholder="Celular"
          />
        </View>
        <View
          style={{
            flexDirection: isMobile ? "column" : "row",
            gap: isMobile ? 0 : 20,
          }}
        >
          <InputMask
            type="custom"
            options={{ mask: "99/99/9999" }}
            name="data_nascimento"
            label="Data de Nascimento"
            placeholder="Data de Nascimento"
          />

          <Input
            name="email"
            placeholder="E-mail"
            label="E-mail"
            size={22}
            color={"#313131"}
          />
        </View>

        {ingresso?.lote?.setor?.extra_fields?.map((campo, index) => {
          const options = campo.choices.map((opcao) => ({
            label: opcao,
            value: opcao,
          }));

          const errorMessage =
            formRef.current &&
            formRef.current.getFieldError &&
            formRef.current.getFieldError(`extras.${campo.field_name}`);

          const combinedStyles = {
            ...selectStyles,
            control: (base, state) => ({
              ...base,
              borderColor: errorMessage
                ? "red"
                : state.isFocused
                  ? "#01AC8A"
                  : base.borderColor,
              boxShadow: state.isFocused ? "0 0 0 1px #01AC8A" : base.boxShadow,
              "&:hover": {
                borderColor: errorMessage ? "red" : "#01AC8A",
              },
            }),
          };

          return (
            <View key={index} style={{ marginBottom: 16 }}>
              <Text style={{ fontWeight: "bold", marginBottom: 8, fontSize: 16 }}>
                {campo.field_name}
              </Text>

              <Select
                name={`extras.${campo.field_name}`}
                options={options}
                placeholder="Selecione uma opção..."
                value={
                  selectedExtras[campo.field_name]
                    ? {
                      label: selectedExtras[campo.field_name],
                      value: selectedExtras[campo.field_name],
                    }
                    : null
                }
                onChange={(selected) => {
                  setSelectedExtras((prev) => ({
                    ...prev,
                    [campo.field_name]: selected.value,
                  }));
                }}
                styles={combinedStyles}
                menuPortalTarget={document.body}
                menuPosition="absolute"
                menuPlacement="auto"
              />

              {errorMessage && (
                <Text style={{ color: "red", fontSize: 14, marginTop: 4 }}>
                  {errorMessage}
                </Text>
              )}
            </View>
          );
        })}

        <View style={{ marginTop: 24, marginBottom: 40 }}>
          <Button
            title={
              loading
                ? "Carregando..."
                : etapaAtual + 1 < numEtapas
                  ? "Próximo"
                  : "Reservar Ingressos"
            }
            onPress={() => formRef.current.submitForm()}
            color="#04AD8A"
            disabled={loading}
          />
        </View>

      </Form>
    </ScrollView>
  );
};
