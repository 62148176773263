import React, { useState, createContext, useContext, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

import LightboxContext from "./lightbox";
import PagamentoContext from "./pagamento";
import EventoContext from "./evento";
import API from "../services/API";
import AuthContext from "./auth";
import { create } from "apisauce";

const CompraContext = createContext({});

// const baseUrl = "http://localhost:8005";

// export const defaultApplicationToken =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJpcGFzcyIsImludGVncmFjYW9faWQiOiIxNTc0YmRkYjc1Yzc4YTZmZDIyNTFkNjFlMjk5M2I1MTQ2MjAxMzE5IiwiaWF0IjoxNjE0NzA2MDE1fQ.RqWSS2AXc7ryhFMeXsSrY03mN4ny1Qo4vGj7qCNf8nw";

// const _api = create({
//   baseURL: baseUrl,
//   params: {
//     token: defaultApplicationToken, // Token de autorização para utilização da _api
//   },
// });

// _api.addResponseTransform((response) => {
//   if (!response.ok) throw response;
// });

// _api.addRequestTransform((request) => async () => {
//   //console.log(`${request.method.toLocaleUpperCase()} - ${request.url}`)

//   const token = await AsyncStorage.getItem(`x-access-token`);

//   //console.log(`addRequestTransform`, token)

//   // Montar cabeçalho
//   if (token) request.headers[`x-access-token`] = token; // Token de sessão*/
//   //if (token) API.setHeader("x-access-token", token)

//   return request;
// });

export const CompraProvider = ({ children }) => {
  const { evento } = useContext(EventoContext);
  const { setLoading, setError, setPagina } = useContext(LightboxContext);
  const { setErroPagamento } = useContext(PagamentoContext);
  const { pessoa } = useContext(AuthContext);

  const [carrinho, setCarrinho] = useState([]);
  const [reserva, setReserva] = useState(null);
  const [formasPagamento, setFormasPagamento] = useState(null);
  const [tokenReserva, setTokenReserva] = useState(null);
  const [valorTotal, setValorTotal] = useState(0);
  const [quantidadeTotal, setQuantidadeTotal] = useState(0);
  const [compra, setCompra] = useState(null);
  const [ingressos, setIngressos] = useState([]);
  const [ingressosAcompanhantes, setIngressosAcompanhantes] = useState([]);

  const [valoresDoacao, setValoresDoacao] = useState({});

  const [doSubmit, setDoSubmit] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [formAlterado, setFormAlterado] = useState(false);

  const [modoPagamento, setModoPagamento] = useState(null);
  const [dataHoraLimite, setDataHoraLimite] = useState(null);

  const [modoCompra, setModoCompra] = useState("normal"); // "normal" ou "link" (link de pagamento, vindo de comissário)

  useEffect(() => {
    defineQuantValor();
  }, [valoresDoacao]);

  useEffect(() => {
    //console.log("ATUALIZOU COMPRA", compra)
  }, [compra]);

  const putIngresso = (ingresso) => {
    console.log("{ putIngresso }", ingresso, ingressos);

    setIngressos((ingressosAtuais) => {
      let ings = [...ingressosAtuais];

      for (let i = 0; i < ings.length; i++) {
        if (ings[i]._id == ingresso._id) {
          ings[i] = ingresso;
          break;
        }
      }

      return ings;
    });
  };

  const defineQuantValor = () => {
    setValorTotal(getTotal());
    setQuantidadeTotal(getQtdIngressos());
  };

  const efetuarCompra = async (dados) => {
    try {
      if (dados?.meio_pagamento != "pix")
        setLoading(true, "Confirmando aquisição");

      dados = { ...dados, reserva_token: tokenReserva };

      console.log("dados", dados);

      let url = {
        normal: "/compras",
        link: "/compras/link",
      };

      let resultCompra = await API.post(url[modoCompra], dados);
      let compra = resultCompra.data;

      setCompra(() => compra);

      if (compra.status == 1) {
        setPagina(pessoa?.faceURL ? "reconhecimento" : "confirmacao");
      } else if (compra.status == 2) {
        if (compra.meio_pagamento != "pix") setPagina("processamento");
      } else {
        // setPagina("pagamento")
        setErroPagamento(
          compra?.fatura?.cartao_credito?.mensagem_retorno ||
          "Ocorreu um erro no pagamento. Verifique seus dados e tente novamente!"
        );
      }

      if (dados?.meio_pagamento != "pix" && compra.status == 1) {
        setTokenReserva(null);
        if (evento) {
          AsyncStorage.removeItem(`iPass-LB-token-reserva-${evento.etapa._id}`);
        }
      }

      setLoading(false);

      return compra;
    } catch (error) {
      console.error(error);
      setError(true, "Ocorreu um erro na confirmação da compra");
      setLoading(false);
    }
  };

  const getQtdIngressos = () => {
    let qtd = 0;

    carrinho.forEach((ingresso) => {
      qtd += ingresso.quantidade;
    });

    return qtd;
  };

  const getTotal = () => {
    //console.log("getTotal", valoresDoacao)

    let valores = {
      pix: 0,
      "cartao-credito": 0,
    };

    carrinho.forEach((ingresso) => {
      console.log("INGRESSO", ingresso);
      /*let taxa = ingresso.valor_taxa_conveniencia ? ingresso.valor_taxa_conveniencia : 0
            valor += (ingresso.valor + taxa) * ingresso.quantidade*/
      for (let key of Object.keys(ingresso.lote.taxas)) {
        let valorTaxa = !ingresso.lote.absorver_taxas
          ? +ingresso.lote.taxas[key].valor_taxa
          : 0;
        //console.log("valorTaxa", ingresso.valor, valorTaxa, ingresso.quantidade)
        if (!valores[key]) valores[key] = 0;

        valores[key] += (ingresso.valor + valorTaxa) * ingresso.quantidade;
      }
    });

    for (let key of Object.keys(valores)) {
      Object.keys(valoresDoacao).forEach((keyValor) => {
        valoresDoacao[keyValor].forEach((val) => {
          valores[key] += val.valor_doacao;
          //console.log("SOMAAA", key, val.valor_doacao, valores[key])
        });
      });
    }

    console.log("VALORES", valores);

    return valores;
  };

  const addCarrinho = (ingresso, quantidade = null) => {
    console.log("{ addCarrinho }", ingresso, quantidade);
    let ingressos = carrinho;

    let achou = false;
    for (let ing of ingressos) {
      if (ing.lote._id == ingresso._id) {
        if (quantidade) ing.quantidade = quantidade;
        else ing.quantidade++;
        achou = true;
        break;
      }
    }

    let valor = ingresso.lote ? ingresso.lote.valor : ingresso.valor;
    //let taxa = ingresso.valor_taxa_conveniencia ? ingresso.valor_taxa_conveniencia : 0
    //let total = valor + taxa

    if (!achou) {
      ingressos.push(
        ingresso.lote
          ? {
            ...ingresso,
            quantidade: quantidade ? quantidade : 1,
            valor: valor,
          }
          : {
            lote: ingresso,
            quantidade: quantidade ? quantidade : 1,
            valor: valor,
          }
      );
    }

    defineQuantValor();
  };

  const removeCarrinho = (ingresso) => {
    setCarrinho((ingressos) => {
      for (let i = 0; i < ingressos.length; i++) {
        if (ingressos[i].lote._id == ingresso._id) {
          ingressos[i].quantidade--;
          if (ingressos[i].quantidade == 0) ingressos.splice(i, 1);
          break;
        }
      }
      return [...ingressos];
    });

    defineQuantValor();
  };

  const reservarIngressos = async ({ titulares = [] }) => {
    // console.log("{ reservarIngressos }", carrinho)
    try {
      setLoading(true, "Reservando ingressos");

      let dados = {
        evento_id: evento._id,
        ingressos: [],
      };

      carrinho.forEach((ingresso) => {
        dados.ingressos.push({
          lote_id: ingresso.lote._id,
          quantidade: ingresso.quantidade,
          titulares: titulares.filter((t) => t.lote_id == ingresso?.lote?._id),
        });
      });

      let response = await API.post("/compras/reservas", dados);

      let { token: tokenReserva, reserva, formas_pagamento } = response.data;

      setReserva(() => reserva);
      setTokenReserva(tokenReserva);

      setFormasPagamento(formas_pagamento);
      setDataHoraLimite(reserva.datahora_limite);

      //console.log("TOKEN RESERVA VINDO DA API", tokenReserva)

      await AsyncStorage.setItem(
        `iPass-LB-token-reserva-${evento.etapa._id}`,
        tokenReserva
      );

      // setPagina("selecionarTitular");
      setPagina("pagamento");
      setLoading(false);
    } catch (error) {
      console.log("ERROOOOO", error);
      setLoading(false);
      //if error is string
      console.log("ERROOOOO", error.response);

      if (error.data) return setError(true, error.data);
      setError(true, "Ocorreu um erro na reserva dos ingressos!");
    }
  };

  return (
    <CompraContext.Provider
      value={{
        carrinho,
        valorTotal,
        quantidadeTotal,
        addCarrinho,
        removeCarrinho,
        efetuarCompra,
        tokenReserva,
        setTokenReserva,
        reservarIngressos,
        reserva,
        setReserva,
        compra,
        setCompra,
        ingressos,
        putIngresso,
        formasPagamento,

        valoresDoacao,
        setValoresDoacao,

        doSubmit,
        setDoSubmit,
        submitResponse,
        setSubmitResponse,
        formAlterado,
        setFormAlterado,

        modoPagamento,
        setModoPagamento,

        setIngressos,

        modoCompra,
        setModoCompra,

        dataHoraLimite,
        setDataHoraLimite,
        setIngressosAcompanhantes,
        ingressosAcompanhantes,
      }}
    >
      {children}
    </CompraContext.Provider>
  );
};

export default CompraContext;
