import React, { useEffect, useContext } from "react";
import { View, Text, Image } from "react-native";
import { isMobile } from "react-device-detect";

import styles from "./styles";
import Colors from "../../values/colors";

// Contexts
import WebSocketContext from "../../contexts/webSocket";
import LightboxContext from "../../contexts/lightbox";
import PagamentoContext from "../../contexts/pagamento";

import Icon from "react-native-vector-icons/FontAwesome5";
import * as Animatable from "react-native-animatable";
const AnimatedIcon = Animatable.createAnimatableComponent(Icon);

import { Logger } from "../../helpers/Logger";
import CompraContext from "../../contexts/compra";

import API from "../../services/API";
import AuthContext from "../../contexts/auth";

const Processamento = () => {
  const { setPagina } = useContext(LightboxContext);
  const { onSocket } = useContext(WebSocketContext);
  const { reserva, setReserva, compra, setCompra } = useContext(CompraContext);
  const { pessoa } = useContext(AuthContext);
  const { setErroPagamento } = useContext(PagamentoContext);

  useEffect(() => {
    const handleSearchCompra = async (id) => {
      try {
        let ordem = await API.get(
          `https://homologacao.api.ipassingressos.com.br/compras/${id}`  // prod
          // `https://homolog.api.ipassingressos.com.br/compras/${id}` //hml
        );
        let data = ordem.data;
        if (data) {
          if (data.status == 1) {
            setPagina(pessoa?.faceURL ? "reconhecimento" : "confirmacao");
          } else if ([3, 4].includes(data.status)) {
            setPagina("doacao");
            setErroPagamento(
              data?.fatura?.cartao_credito?.mensagem_retorno ||
              "Ocorreu um erro no pagamento. Verifique seus dados e tente novamente!"
            );
          }
        }
      } catch (error) { }
      setTimeout(() => {
        handleSearchCompra(id);
      }, 5000); // A cada 5 segundos
    };
    handleSearchCompra(compra._id);

    setTimeout(() => consultarCompra(compra._id), 5000);

    /*onSocket(`reserva-atualizada:${reserva._id}`, data => { // Pagamento feito e doação atualizada
            //Logger.log("RESERVA ATUALIZADA", data)

            setReserva(data)

            if (data.status == 1) {
                setPagina("confirmacao")
            } else if ([3, 4].includes(data.status)) {
                setPagina("pagamento")
                setErroPagamento("Ocorreu um erro no pagamento. Verifique seus dados e tente novamente!")
            }
        })

        onSocket(`compra-atualizada:${compra._id}`, data => { // Pagamento feito e doação atualizada
            //Logger.log("COMPRA ATUALIZADA", data)

            setCompra(() => data)

            if (data.status == 1) {
                setPagina("confirmacao")
            } else if ([3, 4].includes(data.status)) {
                setPagina("pagamento")
                setErroPagamento("Ocorreu um erro no pagamento. Verifique seus dados e tente novamente!")
            }
        })*/

    /*setTimeout(() => {
            setPagina("doacao")
            setErroPagamento(true)
        }, 3000)*/
  }, []);

  const consultarCompra = async (compra_id) => {
    try {
      let response = await API.get(`/compras/${compra_id}`);
      let compra = response.data;

      setCompra(() => compra);

      if (compra.status == 1) {
        setPagina(pessoa?.faceURL ? "reconhecimento" : "confirmacao");
      } else if ([3, 4].includes(compra.status)) {
        setPagina("pagamento");
        setErroPagamento(
          compra?.fatura?.cartao_credito?.mensagem_retorno ||
          "Ocorreu um erro no pagamento. Verifique seus dados e tente novamente!"
        );
      } else setTimeout(() => consultarCompra(compra_id), 3000);
    } catch (error) {
      throw error;
    }
  };

  return (
    <View style={styles.container}>
      <Text
        style={{
          fontSize: 32,
          color: Colors.primary,
          fontWeight: 500,
          marginBottom: 20,
        }}
      >
        QUASE LÁ!
      </Text>
      <Text style={{ fontSize: 24, fontWeight: 300, textAlign: "center" }}>
        Estamos processando seu pagamento, aguarde.
      </Text>

      {/*<Image style={{
                width: 530 / (isMobile ? 1.75 : 1),
                height: 327 / (isMobile ? 1.75 : 1),
                marginVertical: 20
            }} source={{
                uri: ProcessandoPagamento
            }} />*/}

      <AnimatedIcon
        name="spinner"
        size={120}
        style={{ marginVertical: 50 }}
        color="#000"
        animation="rotate"
        iterationCount={"infinite"}
        useNativeDriver
      />

      <Text style={{ fontSize: 16, marginVertical: 20, textAlign: "center" }}>
        Não saia dessa página, te avisaremos por aqui mesmo.
      </Text>
    </View>
  );
};

export default Processamento;
