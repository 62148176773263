import React, { useState, useContext, useEffect } from 'react'
import { View, TouchableOpacity, Text } from 'react-native'
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { formatReal } from '../../helpers/Format'

import styles from './styles'

import CompraContext from '../../contexts/compra';

const Modalidade = ({ ingresso }) => {
    const { carrinho, addCarrinho, removeCarrinho, getQtdIngressos } = useContext(CompraContext)

    const [error, setError] = useState(false)
    const [quantidade, setQuantidade] = useState(0)

    useEffect(() => {
        getQtd()

    }, [])

    useEffect(() => {
    }, [ingresso])

    const acaoIngresso = (acao) => {
        console.log("acaoIngresso", acao, ingresso)
        if (acao == "mais") {
            if ((ingresso?.setor?.gerar_ingresso_grupo && quantidade == 1) || (quantidade == ingresso?.compra_maxima)) {
                setError(true)
                return
            }
            addCarrinho(ingresso)
        } else if (acao == "menos") {
            setError(false)
            removeCarrinho(ingresso)
        }

        getQtd()
    }

    const getQtd = () => {
        console.log("getQtd", ingresso, carrinho)
        let result = carrinho.filter(ing => { return ing.lote._id == ingresso._id })
        console.log("RESULT", result)
        if (result.length == 0) setQuantidade(() => 0)
        else setQuantidade(() => result[0].quantidade)
    }

    return (
        <View style={styles.container}>
            <View>
                <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontSize: 20, fontWeight: '500' }}>{ingresso?.modalidade?.nome}</Text>
                    <Text style={{ fontSize: 12, margin: 6 }}>({ingresso.nome})</Text>
                </View>
                <View style={{ flexDirection: 'row', marginTop: 5 }}>
                    <Text style={{ fontSize: 14, color: "#04AD8A" }}>{ingresso.valor > 0 ? formatReal(ingresso.valor) : `GRÁTIS`}</Text>
                    {(ingresso.valor > 0 && !ingresso.absorver_taxas) && <Text style={{ fontSize: 12, marginTop: 2, marginLeft: 5 }}>+ taxas{/*formatReal(ingresso.valor_taxa_conveniencia)*/}</Text>}
                </View>
            </View>

            <View>
                <View style={{ flex: 1, flexDirection: 'row', alignSelf: 'flex-end' }}>
                    <View style={styles.areaControleQuantidade}>
                        <TouchableOpacity style={[styles.botaoMaisMenos, quantidade == 0 ? styles.botaoDisabled : {}]} onPress={() => { quantidade > 0 ? acaoIngresso("menos") : null }}>
                            <FontAwesome5 name={'minus'} color="#FFF" />
                        </TouchableOpacity>

                        <Text style={{ width: 60, alignSelf: 'center', textAlign: 'center', color: '#04AD8A' }}>{quantidade}</Text>

                        <TouchableOpacity style={[styles.botaoMaisMenos, ingresso.compra_maxima && quantidade == ingresso.compra_maxima ? styles.botaoDisabled : {}]} onPress={() => { acaoIngresso("mais") }}>
                            <FontAwesome5 name={'plus'} color="#FFF" />
                        </TouchableOpacity>
                    </View>
                </View>
                {error && <Text style={{ color: "#F00", fontSize: 12 }}>Máximo de {(ingresso?.setor?.gerar_ingresso_grupo || ingresso.compra_maxima == 1) ? '1 unidade' : `${ingresso.compra_maxima} unidades`}</Text>}
            </View>
        </View>
    )
}

export default Modalidade