import { create } from "apisauce";
import AsyncStorage from "@react-native-async-storage/async-storage";

const baseUrl = "https://homologacao.api.ipassingressos.com.br"; //prod
// const baseUrl = "https://homolog.api.ipassingressos.com.br"; //hml

export const defaultApplicationToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJpcGFzcyIsImludGVncmFjYW9faWQiOiIxNTc0YmRkYjc1Yzc4YTZmZDIyNTFkNjFlMjk5M2I1MTQ2MjAxMzE5IiwiaWF0IjoxNjE0NzA2MDE1fQ.RqWSS2AXc7ryhFMeXsSrY03mN4ny1Qo4vGj7qCNf8nw";

const API = create({
  baseURL: baseUrl,
  params: {
    token: defaultApplicationToken,
  },
});

API.addRequestTransform(async (request) => {

  try {
    const sessionToken = await AsyncStorage.getItem(`x-access-token`);
    if (sessionToken) {
      request.headers[`x-access-token`] = sessionToken;
    }
  } catch (error) {
    console.warn("Erro ao buscar o token de sessão:", error);
  }

  return request;
});

API.addResponseTransform((response) => {
  if (!response.ok) {
    console.error("Erro na resposta da API:", response.problem || "Erro desconhecido");
    throw response;
  }
});

export default API;
